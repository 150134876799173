export const WAITING_TYPE_MESSAGE = 'WAITING_TYPE_MESSAGE';
export const WAITING_TYPE_INFO = 'WAITING_TYPE_INFO';
export const WAITING_TYPE_ERROR = 'WAITING_TYPE_ERROR';

export const appModes = {
    VERSIEGELTE_FLAECHEN : 'APP_MODE/VERSIEGELTE_FLAECHEN',
    ESW : 'APP_MODE/ESW',
    INFO : 'APP_MODE/INFO',
    VERSICKERUNG : 'APP_MODE/VERSICKERUNG',
    STAC: 'APP_MODE/STAC',
    USER_PW: 'APP_MODE/USER_PW',
};

