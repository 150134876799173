const VERDISONLINEVERSION = '2.4.3';
const VERDISONLINEHASH = '#03f232521ac1b3a6c24f717d12b110ab1ef5440d';
/* eslint-disable no-useless-concat */
export const getVersion = () => {
	if (VERDISONLINEVERSION === '%VERDIS_ONLINE' + '_' + 'VERSION%') {
		return 'dev-hot-reload';
	} else {
		return VERDISONLINEVERSION;
	}
};
export const getMapHash = () => {
	if (VERDISONLINEHASH === '%VERDIS_ONLINE' + '_' + 'HASH%') {
		return '#dev-hot-reload';
	} else {
		return VERDISONLINEHASH;
	}
};
