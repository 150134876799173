import React from "react";
const StaedtStrassenflaeche = () => {
    return (
        <div>
            <strong>Städtische Straßenfläche:</strong>
            <br />
            Eine öffentliche Straßenfläche die der Stadt.
            <br />
        </div>
    );
};

export default StaedtStrassenflaeche;
